<template>
  <v-expansion-panels focusable multiple v-model="show">
    <v-expansion-panel>
      <v-expansion-panel-header class="subheading font-weight-bold pa-1">
        <h3>{{ $t("studies.acv.numbers.title") }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense>
          <!-- Loss -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.loss')"
            :value.sync="impact.loss"
            :isEditable="isEditable"
          />

          <!-- Avg KM -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.avgKM')"
            :value.sync="impact.avgKM"
            :isEditable="isEditable"
          />

          <!-- resourceUseMineralsMetals -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.resourceUseMineralsMetals')"
            :value.sync="impact.resourceUseMineralsMetals"
            :isEditable="isEditable"
          />

          <!-- ResourceUseFossils -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.resourceUseFossils')"
            :value.sync="impact.resourceUseFossils"
            :isEditable="isEditable"
          />

          <!-- Acidification -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.acidification')"
            :value.sync="impact.acidification"
            :isEditable="isEditable"
          />

          <!--- EcotoxicityFreshWater -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.ecotoxicityFreshWater')"
            :value.sync="impact.ecotoxicityFreshWater"
            :isEditable="isEditable"
          />

          <!-- HumanToxicityCancer -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.humanToxicityCancer')"
            :value.sync="impact.humanToxicityCancer"
            :isEditable="isEditable"
          />

          <!-- humanToxicityNonCancer -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.humanToxicityNonCancer')"
            :value.sync="impact.humanToxicityNonCancer"
            :isEditable="isEditable"
          />

          <!-- eutrophicationFreshWater -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.eutrophicationFreshWater')"
            :value.sync="impact.eutrophicationFreshWater"
            :isEditable="isEditable"
          />

          <!-- eutrophicationMarine -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.eutrophicationMarine')"
            :value.sync="impact.eutrophicationMarine"
            :isEditable="isEditable"
          />

          <!-- eutrophicationTerrestrial -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.eutrophicationTerrestrial')"
            :value.sync="impact.eutrophicationTerrestrial"
            :isEditable="isEditable"
          />

          <!-- climateChange -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.climateChange')"
            :value.sync="impact.climateChange"
            :isEditable="isEditable"
          />

          <!-- climateChangeBiogenic -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.climateChangeBiogenic')"
            :value.sync="impact.climateChangeBiogenic"
            :isEditable="isEditable"
          />

          <!-- climateChangeFossil -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.climateChangeFossil')"
            :value.sync="impact.climateChangeFossil"
            :isEditable="isEditable"
          />

          <!-- climateChangeLandUse -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.climateChangeLandUse')"
            :value.sync="impact.climateChangeLandUse"
            :isEditable="isEditable"
          />

          <!-- ionisingRadiation -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.ionisingRadiation')"
            :value.sync="impact.ionisingRadiation"
            :isEditable="isEditable"
          />

          <!-- landuse -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.landuse')"
            :value.sync="impact.landuse"
            :isEditable="isEditable"
          />

          <!-- ozoneDepletion -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.ozoneDepletion')"
            :value.sync="impact.ozoneDepletion"
            :isEditable="isEditable"
          />

          <!-- particulateMatter -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.particulateMatter')"
            :value.sync="impact.particulateMatter"
            :isEditable="isEditable"
          />

          <!-- photochemicalOzoneFormation -->
          <ProductACVItemDetails
            :label="
              $t('studies.acv.numbers.labels.photochemicalOzoneFormation')
            "
            :value.sync="impact.photochemicalOzoneFormation"
            :isEditable="isEditable"
          />

          <!-- waterUse -->
          <ProductACVItemDetails
            :label="$t('studies.acv.numbers.labels.waterUse')"
            :value.sync="impact.waterUse"
            :isEditable="isEditable"
          />
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ProductACVNumbers",

  components: {
    ProductACVItemDetails: () =>
      import("@/components/Studies/ACV/ProductACVItemDetails"),
  },

  props: {
    impact: { type: Object, default: () => {} },
    isEditable: { type: Boolean, default: false },
  },

  data() {
    return {
      show: [],
      form: {},
      formKeys: [
        "loss",
        "avgKM",
        "resourceUseMineralsMetals",
        "ressourceUseFossils",
        "acidification",
        "ecotoxicityFreshWater",
        "humanToxicityCancer",
        "humanToxicityNonCancer",
        "eutrophicationFreshWater",
        "eutrophicationMarine",
        "eutrophicationTerrestrial",
        "climateChange",
        "climateChangeBiogenic",
        "climateChangeFossil",
        "climateChangeLandUse",
        "ionisingRadiation",
        "landuse",
        "ozoneDepletion",
        "particulateMatter",
        "photochemicalOzoneFormation",
        "waterUse",
      ],
    };
  },

  created() {
    if (this.isEditable) {
      // this.form = this.$R.pick(this.formKeys, this.impact);
    }
  },
};
</script>

<style scoped></style>
